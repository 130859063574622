<template>
    <div v-if="plexUserFilled">
        <v-card class="!bg-transparent mt-5" :class="formShadow">
            <v-card-title>
                <div class="flex items-center">
                    <img class="rounded-full mr-2 w-10 h-10" :src="plexUser.thumb" :alt="plexUser.email"/>
                    <div class="text-sm leading-none">
                        <p class="leading-none mb-0" v-html="plexUser.username"/>
                        <p class="mb-0" v-html="plexUser.email"/>
                    </div>
                </div>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.id') }}</v-label>
                        <div>
                            {{ plexUser.id }}
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.uuid') }}</v-label>

                        <div>
                            {{ plexUser.uuid }}
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.authToken') }}</v-label>

                        <div>
                            {{ plexUser.auth_token }}
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.serverAuthToken') }}</v-label>

                        <div>
                            {{ plexUser.server_auth_token }}
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.joinedAt') }}</v-label>

                        <div>
                            <relative-time :date="plexUser.joined_at"/>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.createdAt') }}</v-label>

                        <div>
                            <relative-time :date="plexUser.created_at"/>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label>{{ $t('Plex.plexUser.updatedAt') }}</v-label>

                        <div>
                            <relative-time :date="plexUser.updated_at"/>
                        </div>
                    </v-col>
                </v-row>

                <v-divider class="mt-2 mb-1"/>
                <plex-form flat sync-label delete-button/>
            </v-card-text>
        </v-card>
    </div>
    <plex-form v-else sync-label/>
</template>

<script>
import {mapGetters} from 'vuex';
import PlexForm     from './Form';

export default {
    components: {
        PlexForm,
    },

    props: {},

    watch: {},

    mounted() {},

    data: () => ({}),

    methods: {},

    computed: {
        formShadow() {
            return this.highlightedBackgroundShadow();
        },

        plexUserFilled() {
            return typeof this.plexUser === 'object' && this.plexUser.id && this.plexUser.id !== '';

        },

        ...mapGetters('User', ['plexUser']),
    },
};
</script>
