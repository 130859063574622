<template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
        <v-card class="!bg-transparent mt-5" :flat="flat" :class="formShadow">
            <v-card-text class="pb-0">
                <v-form ref="form" @submit.prevent="handleSubmit(associate)" @keyup.native.enter="handleSubmit(associate)">
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                        >
                            <text-field-with-validation
                                rules="required"
                                :label="$t('Plex.form.username')"
                                name="username"
                                v-model="username"
                                prepend-icon="$person"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                        >
                            <text-field-with-validation
                                rules="required"
                                v-model="password"
                                :label="$t('Plex.form.pass')"
                                name="password"
                                prepend-icon="$lock"
                                :type="showPassword ? 'text' : 'password'">
                                <template v-slot="append">
                            <span @click="showPassword = ! showPassword" class="cursor-pointer">
                                <v-icon v-html="! showPassword ? '$eye' : '$eyeSlash'"/>
                            </span>
                                </template>
                            </text-field-with-validation>
                        </v-col>

                        <v-col
                            v-if="showPlexTfaFeld"
                            cols="12"
                            md="6"
                            lg="4"
                        >
                            <text-field-with-validation
                                v-model="plexTfa"
                                autocomplete="plexTfa"
                                label="Plex TFA Code (falls Aktiv bei Plex)"
                                aria-label="Plex TFA"
                                aria-required="true"
                                name="plexTfa"
                                prepend-icon="$key"
                                type="text"
                                hint="Solltest du bei deinem Plex-Account die 2 Faktor Auth aktiv haben, so kannst du diese hier angeben, bis wir den direkten Login via Plex unterstützen."
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                        >
                            <v-switch
                                v-model="syncPassword"
                                persistent-hint
                                :hint="$t('Plex.form.syncPassHint')"
                                :label="$t('Plex.form.syncPass')"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-form>

                <v-alert
                    class="mt-2"
                    type="error"
                    :value="alert.visible"
                    :dismissible="true"
                    transition="fade-transition"
                >
                    <nl2br tag="span" :text="alert.text"/>
                </v-alert>
            </v-card-text>

            <v-card-actions class="pt-2 d-inline">
                <v-row class="p-3">
                    <v-col :md="deleteButton ? 6 : 12">
                        <v-btn small color="success" @click="associate" :disabled="invalid || loadingDelete" :loading="loading">
                            {{ syncLabel ? $t('Plex.form.sync') : $t('Plex.form.associate') }}
                        </v-btn>
                    </v-col>

                    <template v-if="deleteButton">
                        <v-col md="6" class="md:text-right">
                            <v-btn small color="error" @click="remove" :disabled="loading" :loading="loadingDelete">
                                {{ $t('Plex.form.delete') }}
                            </v-btn>
                        </v-col>
                    </template>
                </v-row>

                <v-row class="p-3 pb-0 pt-0 mt-0">
                    <v-col>
                        <div class="cursor-pointer text-xs text-gray-400" @click="togglePlexTfaField()">
                            <template v-if="showPlexTfaFeld">Zwei Faktor Code Feld für Plex ausblenden</template>
                            <template v-else>Zwei Faktor Code Feld für Plex anzeigen</template>
                        </div>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </ValidationObserver>
</template>

<script>
import TextFieldWithValidation from '@symfia/core/components/VeeValidate/TextFieldWithValidation';

export default {
    components: {
        TextFieldWithValidation,
    },

    props: {
        flat: {
            type:    Boolean,
            default: false,
        },

        syncLabel: {
            type:    Boolean,
            default: false,
        },

        deleteButton: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {},

    mounted() {

    },

    data: () => ({
        username:        '',
        password:        '',
        plexTfa:         '',
        showPlexTfaFeld: false,
        syncPassword:    false,
        showPassword:    false,
        alert:           {
            visible: false,
            text:    '',
        },
        loading:         false,
        loadingDelete:   false,
    }),

    methods: {
        associate() {
            if (this.loading || this.loadingDelete) {
                return;
            }

            this.loading       = true;
            this.alert.visible = false;

            let data = {
                username:            this.username,
                password:            this.password,
                synchronisePassword: this.syncPassword,
            };

            if (this.plexTfa && this.plexTfa !== '') {
                data.plexTfa = this.plexTfa;
            }

            this.$axios.post(this.route('plex.association.synchronise'), data).then((response) => {
                this.$store.dispatch('User/refreshUserInfo', response.data);

                this.$notifications.notify({
                    message: this.$t('Plex.form.successMessage'),
                    type:    'success',
                }, this);
            }).catch((error) => {
                const responseData = this.handleAxiosGlobalResponseErrors(error, this.$refs.form);

                if (responseData.data.message) {
                    if (responseData.form !== true) {
                        this.alert.text               = responseData.data.message;
                        this.alert.visible            = true;
                        this.$refs.form.flags.invalid = true;
                    }
                } else {
                    this.$logger.error('Plex User Association Error', [
                        error.response,
                        error.request,
                        error.message,
                    ], 'plex');

                    this.$notifications.notify({
                        message: this.$t('Plex.form.unknownError'),
                        type:    'error',
                    }, this);
                }
            }).finally(() => {
                this.loading = false;
            });
        },

        remove() {
            if (this.loading || this.loadingDelete) {
                return;
            }

            this.loadingDelete = true;
            this.alert.visible = false;

            this.$axios.delete(
                this.route('plex.association.remove'),
            ).then((response) => {
                this.$store.dispatch('User/refreshUserInfo', response.data);

                this.$notifications.notify({
                    message: this.$t('Plex.form.successRemoveMessage'),
                    type:    'success',
                }, this);
            }).catch((error) => {
                const responseData = this.handleAxiosGlobalResponseErrors(error, this.$refs.form);

                if (responseData.data.message) {
                    if (responseData.form !== true) {
                        this.alert.text               = responseData.data.message;
                        this.alert.visible            = true;
                        this.$refs.form.flags.invalid = true;
                    }
                } else {
                    this.$logger.error('Plex User Association Delete Error', [
                        error.response,
                        error.request,
                        error.message,
                    ], 'plex');

                    this.$notifications.notify({
                        message: this.$t('Plex.form.unknownError'),
                        type:    'error',
                    }, this);
                }
            }).finally(() => {
                this.loadingDelete = false;
            });
        },

        togglePlexTfaField() {
            if (this.showPlexTfaFeld) {
                this.showPlexTfaFeld = false;
                this.plexTfa = '';
                return;
            }

            this.showPlexTfaFeld = true;

        },
    },

    computed: {
        formShadow() {
            if (this.flat) {
                return '';
            }

            return this.highlightedBackgroundShadow();
        },
    },
};
</script>
