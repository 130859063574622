<template>
    <div>
        <div v-if="hasPlex">
            <span class="subtitle-2" v-html="$t('Plex.info.hasPlex')" v-if="! isPlexInvalid"/>
            <v-alert
                v-else
                type="error"
                transition="fade-transition"
            > {{ $t('Plex.info.plexInvalid') }}
            </v-alert>
            <plex-user />
        </div>
        <div v-else>
            <span class="subtitle-2" v-html="$t('Plex.info.noPlex')"/>
            <plex-form/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PlexForm     from './Helper/Form';
    import PlexUser     from './Helper/PlexUser';

    export default {
        components: {
            PlexForm,
            PlexUser
        },

        props: {},

        watch: {},

        mounted() {

        },

        data: () => ({}),

        methods: {},

        computed: {
            ...mapGetters('User', ['hasPlex', 'isPlexInvalid']),
        },
    };
</script>
